export const environment = {
  production: true,
  maxUploadSize: '250', // upload size in mb.
  baseUrl: 'https://app.wafrn.net/api',
  //baseUrl: 'https://app.wafrn.net/api',
  logo: '/assets/logo.png',
  //baseMediaUrl: 'https://cdn.media.wafrn.net',
  baseMediaUrl: 'https://media.wafrn.net',
  //externalCacheurl: 'https://wafrnmediacache.b-cdn.net/?media=',
  //externalCacheurl: 'https://cache2.wafrn.net/?media=',
  //externalCacheurl: 'https://wafrncache.b-cdn.net/api/cache/?media=',
  externalCacheurl: 'https://cdn.wafrn.net/api/cache/?media=',
  frontUrl: 'https://app.wafrn.net',
  shortenPosts: 3,
  reviewRegistrations: true,
  disablePWA: false,
  maintenance: false,
};
